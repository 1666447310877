<template>
  <section id="Entertainment">
    <ExperiencesCarousel :carouselImages="carouselImages" />
  </section>
</template>
  
  <script>
import ExperiencesCarousel from "@/components/global/ExperiencesCarousel";

export default {
  name: "Entertainment",
  components: {
    ExperiencesCarousel,
  },
  data() {
    return {
      carouselImages: [
        {
          image: "Experiences/entertainment-01.jpg",
          title: "Entertainment",
          description:
            "From live music and interactive performers to themed photo booths and immersive activations, these added elements are crafted to elevate the atmosphere and make your event truly unforgettable.",
        },
        {
          image: "Experiences/entertainment-02.jpg",
          title: "Entertainment",
          description:
            "From live music and interactive performers to themed photo booths and immersive activations, these added elements are crafted to elevate the atmosphere and make your event truly unforgettable.",
        },
      ],
    };
  },
};
</script>
  
  <style lang="scss" scoped>
</style>