<template>
  <section id="ExperiencesCarousel">
    <div class="menu-underlay"></div>
    <b-carousel
      v-if="carouselImages.length > 1"
      id="carousel-3"
      :interval=0
      indicators
      controls
    >
      <b-carousel-slide
        v-for="(carouselImage, index) in carouselImages"
        :key="'carouselImage' + index"
      >
        <template v-slot:img>
          <img
            :src="require('@/assets/images/Carousel/' + carouselImage.image)"
            :alt="'image slot' + index"
          >
          <div class="overlay"></div>
          <div class="caption">
            <h1 v-if="carouselImage.title">
              {{ carouselImage.title }}
              <div class="ruler"></div>
            </h1>
            <p>{{ carouselImage.description }}</p>
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <b-carousel
      v-else
      id="carousel-3"
    >
      <b-carousel-slide
        v-for="(carouselImage, index) in carouselImages"
        :key="'carouselImage' + index"
      >
        <template v-slot:img>
          <img
            :src="require('@/assets/images/Carousel/' + carouselImage.image)"
            :alt="'image slot' + index"
          >
          <div class="overlay"></div>
          <div class="caption">
            <h1>
              {{ carouselImage.title }}
              <div class="ruler"></div>
            </h1>
            <p>{{ carouselImage.description }}</p>
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </section>    
</template>

<script>
  export default {
    name: "ExperiencesCarousel",
    props: {
      carouselImages: {
        type: Array,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  #ExperiencesCarousel {
    .menu-underlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 268px;
      height: 100%;
      background-image: url('~@/assets/images/Carousel/generic-gallery-side-bg.jpg');
      box-shadow: 8px 0 8px 0 rgba(0,0,0,0.50);
      z-index: 2;
      @media (max-width: 993px) {
        display: none;
      }
    }
  }
</style>

<style lang="scss">
  /* Bootstrap Overrides */
  #ExperiencesCarousel {
    #carousel-3 {
      overflow: hidden;
      height: 100vh;
      width: 100vw;
      @media (max-width: 993px) {
        height: 700px;
      }
      @media (max-width: $md) {
        height: 600px;
      }
      @media (max-width: $sm) {
        height: 550px;
      }
      .carousel-inner {
        @media (max-width: 993px) {
          height: calc((100vw / 16) * 9 + 220px);
          overflow: visible;
        }
      }
      .carousel-inner img {
        display: block;
        object-fit: contain;
        height: 100vh;
        width: calc(100vw - 268px);
        margin-left: 268px;
        object-position: right;
        @media (max-width: 993px) {
          height: auto;
          width: 100vw;
          margin-left: 0;
          object-fit: contain;
        }
      }
      .carousel-control-next, .carousel-control-prev {
        width: 70px;
        height: 70px;
        top: 50%;
        background-image: linear-gradient(180deg, #525252 0%, #1F1F1F 100%);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
        border-radius: 0 6px 6px 0;
        opacity: 1;
        @media (max-width: 993px) {
          display: none;
        }
      }
      .carousel-control-next {
        right: 0;
        transform: scaleX(-1);
      }
      .carousel-control-prev {
        left: 268px;
      }

      .carousel-control-next-icon, .carousel-control-prev-icon {
        background-image: url("~@/assets/icons/Carousel/Chevron-right-icon.svg");
        width: 28.3px;
        height: 28.3px;
      }
      .carousel-control-next-icon {
        transform: scaleX(-1);
      }
      .carousel-control-prev-icon {
        transform: scaleX(-1);
      }

      .carousel-indicators {
        background-image: linear-gradient(180deg, #525252 0%, #1F1F1F 100%);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
        border-radius: 6px;
        border-radius: 6px;
        width: auto;
        max-width: 40%;
        min-width: 10%;
        height: 34px;
        padding: 14px 23px 16px 23px;
        bottom: 10px;
        left: calc(40% + 268px);
        right: auto;
        transform: translate(-50%);
        @media (max-width: 993px) {
          position: relative;
          max-width: 20%;
          left: 50%;
          bottom: 250px;
          height: 16px;
          border-radius: 8px;
          padding: 6px 13px 7px 13px;
        }
        @media (max-width: $sm) {
          max-width: 40%;
        }
        li {
          width: 20px;
          height: 4px;
          background: #9d9d9d;
          box-shadow: 0 0 8px 0 rgba(255,255,255,0.50);
          border: 0;
          border-radius: 2px;
          border-radius: 2px;
          @media (max-width: 993px) {
            height: 3px;
            width: 12px;
          }
        }
        .active {
          opacity: 0.8;
          background: #FFFFFF;
          box-shadow: 0 0 8px 0 rgba(255,255,255,0.50);
        }
      }
      .overlay {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 650px;
        width: 100%;
        background-image: linear-gradient(0deg, rgba(0,0,0,0.84) 28%, rgba(0,0,0,0.00) 70%);
        @media (max-width: 993px) {
          display: none;
        }
      }
      .caption {
        position: absolute;
        bottom: 47px;
        left: 317px;
        display: flex;
        flex-direction: column;
        @media (max-width: 993px) {
          position: relative;
          left: 50px;
          top: 25px;
        }
        @media (max-width: $sm) {
          position: relative;
          left: 20px;
          top: 25px;
        }

          h1 {
            font-family: 'ArtegraSans-Bold';
            font-size: 35px;
            color: #FFFFFF;
            letter-spacing: 2.19px;
            line-height: 30px;
            text-transform: uppercase;
            height: 39px;
            width: fit-content;
            padding-right: auto;
            margin-bottom: 17px;
            @media (max-width: 993px) {
              font-size: 20px;
              letter-spacing: 1.25px;
              height: auto;
              // flex-wrap: wrap;
            }
            .ruler {
              width: auto;
              height: 2px;
              background: #FFFFFF;
              box-shadow: 0 0 8px 2px rgba(255,255,255,0.80);
              margin-top: 12px;
              @media (max-width: 993px) {
                display: none;
              }
            }
          }
        p {
          font-family: 'ArtegraSans-Regular';
          font-size: 16px;
          color: #FFFFFF;
          letter-spacing: 1.07px;
          line-height: 23px;
          height: 120px;
          width: 554px;
          @media (max-width: 993px) {
            width: 90vw;
            height: auto;
          }
        }
      }
    }
  }
</style>