import { render, staticRenderFns } from "./ExperiencesCarousel.vue?vue&type=template&id=131add67&scoped=true&"
import script from "./ExperiencesCarousel.vue?vue&type=script&lang=js&"
export * from "./ExperiencesCarousel.vue?vue&type=script&lang=js&"
import style0 from "./ExperiencesCarousel.vue?vue&type=style&index=0&id=131add67&lang=scss&scoped=true&"
import style1 from "./ExperiencesCarousel.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "131add67",
  null
  
)

export default component.exports